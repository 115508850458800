import _ from 'lodash';
import React from 'react';
import Validator from 'validator';
import PropTypes from 'prop-types';
import Config from 'constants/Config';
import { Link } from 'react-router-dom';
// import SocialButton from './SocialButton';
import { formatRoute } from 'shared/helpers';
import { Form, Button } from 'semantic-ui-react';
import { InlineMessage } from 'shared/components';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';
import { Pane } from 'shared/components/layouts';

class SigninForm extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    signin: PropTypes.func.isRequired,
    redirect: PropTypes.string,
    intl: intlShape.isRequired,
    updateInfo: PropTypes.func,
  };

  state = {
    data: {
      id: '',
      email: '',
      password: '',
    },
    loading: false,
    redirect: false,
    errors: {},
    authError: '',
    loadingChange: false,
    openModal: false,
    showPassword: false,
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.email) {
      errors.email = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
    }
    if (!data.password) {
      errors.password = intl.formatMessage({ id: 'validate.require_field' });
    }

    return errors;
  };

  handleSocialLogin = (res) => {
    if (!res && !res.token.accessToken) return;
    const data = {
      token: res.token.accessToken,
      provider: res.provider,
    };
    this.props
      .signinSocial(data)
      .then(() => {
        const { redirect } = this.props;
        if (this.props.auth.user && !this.props.auth.user.email) {
          this.setState({
            data: {
              id: this.props.auth.user.id,
            },
            openModal: true,
          });
          return;
        }
        this.setState({
          data: {
            id: this.props.auth.user.id,
          },
          openModal: false,
        });
        if (redirect) {
          if (
            redirect === 'products/create' ||
            redirect === 'services/create'
          ) {
            this.props.history.push(
              `/u/${formatRoute(this.props.auth.user.displayName)}/${redirect}`
            );
          } else {
            this.props.history.push(`/${decodeURIComponent(redirect)}`);
          }
        } else {
          if (this.props.auth.user.isFirstLogin == 0) {
            this.props.history.push(`/u/${formatRoute(this.props.auth.user.displayName)}/settings`);
          } else if (this.props.auth.user.business.is_active === 1) {
            this.props.history.push(`/u/${formatRoute(this.props.auth.user.displayName)}`);
          } else {
            this.props.history.push(`/u/${formatRoute(this.props.auth.user.displayName)}/overview`);
          }
        }
      })
      .catch((err) => {
        const { intl } = this.props;

        const authError = intl.formatMessage({
          id: 'validate.verify',
          defaultMessage:
            'This user is not verified. Please check your email and confirm.',
        });
        this.setState({ loading: false, authError });
      });
  };

  handleSocialLoginFailure = () => {};

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    this.setState({errors: { ...this.state.errors, [e.target.name]: '' }});

  };

  validateUpdateEmail = (data) => {
    const errors = {};
    const { intl } = this.props;

    if (!data.email) {
      errors.email = intl.formatMessage({ id: 'validate.require_field' });
    }
    if (data.email && !Validator.isEmail(data.email)) {
      errors.email = intl.formatMessage({ id: 'validate.invalid_email' });
    }

    return errors;
  };

  onSubmit = () => {
    let errors = {};
    errors = this.validate(this.state.data);

    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const data = new URLSearchParams();
      const referenceCode = queryString.parse(this.props.history.location.search).code || '';
      data.append('email', this.state.data.email);
      data.append('password', this.state.data.password);
      if(referenceCode !=''){
        data.append('code', referenceCode);
      }
      this.props.signin(data).then(() => {
        const { redirect } = this.props;
        if (redirect) {
          if (
            redirect === 'products/create' ||
            redirect === 'services/create' ||
            redirect === 'delivery-service'
          ) {
            this.props.history.push(
              `/u/${formatRoute(this.props.auth.user.displayName)}/${redirect}`
            );
          } else {
            this.props.history.push(`/${decodeURIComponent(redirect)}`);
          }
        } else {
          if (this.props.auth.user.isFirstLogin == 0) {
            this.props.history.push(`/u/${formatRoute(this.props.auth.user.displayName)}/settings`);
          } else if (this.props.auth.user.business.is_active === 1) {
            this.props.history.push(`/u/${formatRoute(this.props.auth.user.displayName)}`);
          } else {
            this.props.history.push(`/u/${formatRoute(this.props.auth.user.displayName)}/overview`);
          }
        }
      });
    }
  };
  onClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  updateProfile = () => {
    let errors = {};
    errors = this.validate(this.state.data);
    const { intl } = this.props;
    this.setState({ errors });

    const data = new FormData();

    data.append('id', this.state.data.id || '');
    data.append('email', this.state.data.email || '');

    this.props
      .updateInfo(data)
      .then(() => {
        this.setState({ loading: false, openModal: false });
        const { redirect } = this.props;
        if (redirect) {
          if (
            redirect === 'products/create' ||
            redirect === 'services/create'
          ) {
            this.props.history.push(
              `/u/${formatRoute(this.props.auth.user.displayName)}/${redirect}`
            );
          } else {
            this.props.history.push(`/${decodeURIComponent(redirect)}`);
          }
        } else {
          this.props.history.push(`/`);
        }
      })
      .catch(() => {
        const error = {
          email: intl.formatMessage({
            id: 'validate.already_taken',
            defaultMessage: 'The email has already been taken.',
          }),
        };
        this.setState({ loading: false, errors: error });
      });
  };

  render() {
    const { errors, authError , showPassword} = this.state;
    const {
      auth,
      history: { location },
    } = this.props;
    return (
      <Pane className="login-form">
        <Form onSubmit={this.onSubmit} noValidate>
          <Form.Field error={!!errors.email} className="form-item">
            <label htmlFor="email" className="form-label">
              E-MAIL
            </label>
            <input
              className="form-input"
              id="email"
              type="text"
              name="email"
              placeholder="Enter email"
              onChange={this.onChange}
              autoComplete='off'
            />
            {errors.email && <InlineMessage text={errors.email} />}
          </Form.Field>
          <Form.Field error={!!errors.password} className="form-item">
            <label htmlFor="password" className="form-label">
              PASSWORD
            </label>
            <div className='input-container'>
              <input
                className="form-input"
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter your password"
                onChange={this.onChange}
              />
              <span className={`fa fa-fw ${showPassword?'fa-eye':'fa-eye-slash'} field-icon toggle-password`} onClick={this.onClickShowPassword}></span>
            </div>
            <span className="help-password">
              <FormattedMessage id="help_password" />
            </span>
            {errors.password && <InlineMessage text={errors.password} />}
          </Form.Field>
          {_.isEmpty(errors) &&
            !authError &&
            !auth.fetching &&
            auth.errors &&
            auth.errors.detail && <InlineMessage text={auth.errors.detail} />}
          {authError && <InlineMessage text={authError} />}
          <Pane className="form-item form-button">
            <Button
              type="submit"
              disabled={auth.fetching}
              loading={auth.fetching && authError === ''}
              className="btn btn-circle btn-form"
            >
              SIGN IN
            </Button>
          </Pane>
        </Form>
        <Link to="/forgot-password" className="form-link">
          Forgot Password ?
        </Link>
        <span className="text-or">OR</span>
        <ul className="form-social">
          <li className="social-item social-fb">
            {/* <SocialButton
              provider="facebook"
              appId={Config.FACEBOOK_CLIENT_ID}
              element={FacebookLoginButton}
              scope={['email']}
              onLoginSuccess={this.handleSocialLogin}
              onLoginFailure={this.handleSocialLoginFailure}
            >
              <img
                src={process.env.PUBLIC_URL + `/assets/images/login/fb.svg`}
                alt="facebook"
                className="social-icon"
              />
            </SocialButton> */}
          </li>
          <li className="social-item social-gm">
            {/* <SocialButton
              provider="google"
              appId={Config.GOOGLE_CLIENT_ID}
              element={GoogleLoginButton}
              scope={['email']}
              onLoginSuccess={this.handleSocialLogin}
              onLoginFailure={this.handleSocialLoginFailure}
            >
              <img
                src={process.env.PUBLIC_URL + `/assets/images/login/gg.svg`}
                alt="gg "
                className="social-icon"
              />
            </SocialButton> */}
          </li>
        </ul>
        <p className="text-registered">
          Not Registerd?&nbsp;
          <Link to={`/signup${location.search}`} className="link-registered">
            <strong>Sign Up</strong>
          </Link>
        </p>
      </Pane>
    );
  }
}

export default injectIntl(SigninForm);
